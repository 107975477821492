import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';

import { GalleryView, HomeView, NewsView, StaticView } from './Views';
import configureStore from './Store'

import './App.scss'
import { Spinner } from './Components';
import { API_URI } from './constants';

console.log(process.env.NODE_ENV)

const client = new ApolloClient({
  uri: API_URI,
  cache: new InMemoryCache(),
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeView />,
  },
  {
    path: "/noticias",
    element: <NewsView />
  },
  {
    path: "/galerias",
    element: <GalleryView />
  },
  {
    path: "/noticias/:id/:slug",
    element: <NewsView />
  },
  {
    path: "/content/:slug",
    element: <NewsView />
  },
  {
    path: "/comunicados/:slug",
    element: <NewsView />
  },
  {
    path: "/:slug",
    element: <StaticView />
  },
]);

const { persistor, store } = configureStore()

function App() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={<Spinner />} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
