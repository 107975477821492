
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import useMobile from '../../Hooks/useMobile';
import { Footer, Header, Spinner } from '../../Components';

import useStatic from "../../Hooks/useStatic";
import { useParams } from "react-router-dom";

export default function NewsView () {
    const { ViewportClass } = useMobile()
    const { slug } = useParams()
    const { staticFile, loading } = useStatic(`${slug}`)

    if (loading || !staticFile) {
        return <Spinner />
    }
    
    return <div className={`App ${ViewportClass}`}>
        <Header />

        <section className="App_Hero" style={{
            backgroundImage: `url(${staticFile.attributes.headerImage.data?.attributes.url})`,
        }}>
            <div className="App_Hero_Gradient" />
        </section>
        
        <main style={{
            padding: '0px 32px',
            marginTop: 32,
            fontSize: 18
        }}>
            <h1 style={{
                lineHeight: '32px'
            }}>{staticFile.attributes.title}</h1>

            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {staticFile.attributes.content}
            </ReactMarkdown>
        </main>

        <Footer />
    </div>
}