import { useLazyQuery } from "@apollo/client";
import { HomeGetScreen } from "../Library";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchUpdate } from "../Store/Actions";
import { MenuTypes, IMenu, INew, IBanner, IGallery, IEvent, IMinuta } from "./type";

export default function useInfo() {
    const [fetch, { data, loading: externalLoading }] = useLazyQuery(HomeGetScreen)
    const { eventos, menues, news, typeEvents, lastRequest, banners, galerias, minuta, antiguasMinutas } = useSelector((state: any) => state)
    const [loading, setLoading] = useState<boolean>(true)
    const dispatch = useDispatch<any>()
    
    useEffect(() => {
        if (lastRequest === undefined || Math.round((Date.now() / 1000) - lastRequest) > 5) {
            fetch()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (data) {
            const pushObject = {} as unknown as any
            if (data?.minutas?.data?.length) {
                const [minuta, ...antiguasMinutas] = data.minutas.data
                pushObject.minuta = minuta
                pushObject.antiguasMinutas = antiguasMinutas
            }
            
            dispatch(fetchUpdate({
                eventos: data.eventos.data,
                menues: data.menues.data,
                news: data.news.data,
                typeEvents: data.typeEvents.data,
                banners: data.banners.data,
                galerias: data.galerias.data,
                lastRequest: Math.round(Date.now() /1000),
                ...pushObject
            }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        setLoading(externalLoading)
    }, [externalLoading])

    const getMenues = (type: MenuTypes) => {
        const _menues = menues as IMenu[]
        return _menues.filter(m => m.attributes.type === type)
    }

    console.log(antiguasMinutas)

    return {
        eventos: eventos as IEvent[],
        menues: menues as IMenu[],
        news: news as INew[],
        typeEvents,
        banners: banners as IBanner[],
        galerias: galerias as IGallery[],
        loading,
        minuta: minuta as IMinuta,
        getMenues,
        antiguasMinutas
    }
}