
import ReactMarkdown from "react-markdown";

import useMobile from '../../Hooks/useMobile';
import { Footer, Header, Spinner } from '../../Components';

import useArticle from "../../Hooks/useArticle";
import { useParams } from "react-router-dom";

export default function NewsView () {
    const { ViewportClass } = useMobile()
    const { id } = useParams()
    const { data, loading } = useArticle(Number(id))

    console.log(data)
    const starred = data?.new?.data

    if (loading || !starred) {
        return <Spinner />
    }
    
    return <div className={`App ${ViewportClass}`}>
        <Header />

        <section className="App_Hero" style={{
            backgroundImage: `url(${starred.attributes.bigImage.data?.attributes.url})`,
        }}>
            <div className="App_Hero_Gradient" />
        </section>
        
        <main style={{
            padding: '0px 32px',
            marginTop: 32,
            fontSize: 18
        }}>
            <h2 style={{
                textTransform: 'uppercase',
                fontSize: 16,
                lineHeight: 0
            }}>{starred.attributes.category.data.attributes.name}</h2>
            <h1 style={{
                lineHeight: '32px'
            }}>{starred.attributes.title}</h1>

            <ReactMarkdown>
                {starred.attributes.content}
            </ReactMarkdown>
        </main>

        <Footer />
    </div>
}