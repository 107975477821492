import { IMinuta } from "../../Hooks/type";

const initialState: any = {
    eventos: [],
    menues: [],
    news: [],
    typeEvents: [],
    banners: [],
    galerias: [],
    minuta: undefined as unknown as IMinuta,
    antiguasMinutas: [] as IMinuta[],
    lastRequest: undefined
}

const updateState = (state: any, props: any) => Object.assign({}, state, {
    ...props
})


/**
 * Siempre retorna el state completo.
 */
function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'UPDATE':
            return updateState(state, {
                ...action.payload
            });
        case 'LOGOUT':
            return updateState(state, {
                token: null,
                userInfo: null,
                isLogged: false
            })
    }
    return state
}

export default rootReducer