import { useLazyQuery } from "@apollo/client";
import { NewsGetArticle } from "../Library";
import { useEffect, useState } from "react";

export default function useArticle(id: number) {
    const [fetch, { data: _data, loading: externalLoading }] = useLazyQuery(NewsGetArticle(id))
    const [loading, setLoading] = useState<boolean>(true)
    const [data,setData] = useState<any>({})

    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (_data) {
            if (_data.news) {
                console.log(_data)
                setData({
                    new: {
                        data: _data.news.data[0]
                    }
                })
            } else {
                setData(_data)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_data])

    useEffect(() => {
        setLoading(externalLoading)
    }, [externalLoading])

    return {
        loading,
        _data,
        data
    }
}