import useMobile from '../../Hooks/useMobile';
import { Footer, Header, Spinner } from '../../Components';
import useInfo from '../../Hooks/useInfo';
import { IBanner, IEvent, IGallery, INew } from '../../Hooks/type';

import './HomeView.scss'
import ReactMarkdown from 'react-markdown';

const parseNews = (news: INew[]) => {
    const [starred, ...blocks] = news
    const [top1, top2, ...oldest] = blocks
    return { starred, blocks: [top1, top2], oldest }
}

const BlocksContainer = ({ news, prefix = 'Blocks' }: { news: INew[], prefix?: string }) => {
    return <div className={`${prefix}Container`}>
        {news.map(_new => (
            <article className={`${prefix}Container__Article`}>
                <div className={`${prefix}Container__Article__Content${_new.attributes.smallImage.data?.attributes.url ? ` ${prefix}Container__Article__ContentWImage` : ''}`} style={{
                    background: `url(${_new.attributes.smallImage.data?.attributes.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center right',
                    backgroundSize: 'contain'
                }}>
                    <small>{_new.attributes.category.data.attributes.name}</small>
                    <h1>
                        <a href={`/noticias/${_new.id}/${_new.attributes.slug}`}>{_new.attributes.title}</a>
                    </h1>
                    <p>{_new.attributes.dropdownText}</p>
                    
                </div>
            </article>
        ))}
    </div>
}

const BannerWrapper = ({ banners }: { banners: IBanner[] }) => {
    const banner = banners[0] as IBanner
    const hasLink = !!banner.attributes.href

    const render = () => <img src={banner.attributes.image.data?.attributes.url} width={676} alt={banner.attributes.internalName} />

    return <div className="BannerWrapper">
        {/* // TODO: Collect clics on this {banner.id} */}
        {hasLink && <a href={banner.attributes.href} target='_new'>{render()}</a>}
        {!hasLink && render()}
    </div>
}

const GalleryWrapper = ({ galerias, perRow }: { galerias: IGallery[], perRow: number }) => {
    const { isMobile } = useMobile()

    return <div className="GalleryWrapper">
        {galerias.map(gallery => (
            <div className="GalleryWrapper__Item">
                <a href={gallery.attributes.href} target='_new' className="GalleryWrapper__Item__Link">
                    <img className="GalleryWrapper__Item__Image" src={gallery.attributes.portrait.data?.attributes.url} alt={gallery.attributes.title} />
                </a>
                {isMobile && <div className="GalleryWrapper__Item__Information">
                    <h4>{gallery.attributes.title}</h4>
                    <p>{gallery.attributes.description}</p>
                </div>}
            </div>
        ))}
    </div>
}

const EventsContainer = ({ eventos }: { eventos: IEvent[] }) => {
    return <div className="EventsContainer">
        <div className="EventsContainer__Content">
            <h4>Calendario y Eventos</h4>
            <ul>
                {eventos.map(event => (
                    <li>
                        <div className="tilt" style={{
                            backgroundColor: event.attributes.tipo_evento.data.attributes.color
                        }} />
                        <div className="text">
                            <p>{event.attributes.date}</p>
                            {event.attributes.title}
                            <small>
                                <ReactMarkdown>
                                    {event.attributes.description}
                                </ReactMarkdown>
                            </small>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </div>
}

export default function HomeView () {
    const { ViewportClass } = useMobile()
    const { news, banners, galerias, eventos, loading } = useInfo() // , typeEvents, menues

    const { starred, blocks, oldest } = parseNews(news)

    if (loading) {
        return <Spinner />
    }

    return <div className={`App ${ViewportClass}`}>
        <Header />

        {/* Noticia principal */}
        {starred && <section className="App_Hero" style={{
            backgroundImage: `url(${starred.attributes.bigImage.data?.attributes.url})`,
        }}>
            <div className="App_Hero_Gradient" />
            <h2>{starred.attributes.category.data.attributes.name}</h2>
            <a href={`/noticias/${starred.id}/${starred.attributes.slug}`}>
                <h1>{starred.attributes.title}</h1>
            </a>
            <p>{starred.attributes.dropdownText}</p>
        </section>}

        {/* Barra de noticias alternativas */}
        {blocks && <BlocksContainer news={blocks} />}

        {/* Banner y Galerías de Fotografías */}
        <div className="App_Row">
            <BannerWrapper banners={banners} />
            <GalleryWrapper galerias={galerias} perRow={banners.length ? 4 : 2} />
        </div>

        {/* Otras Noticias y Calendario */}
        <div className="App_Row" style={{
            marginRight: '1rem'
        }}>
            {<BlocksContainer news={oldest} prefix='OtherBlocks' />}
            {<EventsContainer eventos={eventos} />}
        </div>

        <Footer />
    </div>
}