import { gql } from "@apollo/client";

export default gql`
query getHome {
	minutas (
		sort: "publishedAt:desc",
		pagination: {
			limit: 5
		}
	) {
		data {
			id
			attributes {
				title
				pdf {
					data {
						attributes {
							name
							url
						}
					}
				}
			}
		}
	}
	eventos {
		data {
			id
			attributes {
				title
				date
				tipo_evento {
					data {
						id
						attributes {
							name
							color
						}
					}
				}
				description
			}
		}
	}
	typeEvents {
		data {
			id
			attributes {
				name
				color
			}
		}
	}
	menues {
		data {
			id
			attributes {
				title
				link
			}
		}
	}
	galerias (
		sort: "publishedAt:desc",
		pagination: {
			limit: 4
		}
	) {
		data {
			id
			attributes {
				title
				description
				portrait {
					data {
						id
						attributes {
							name
							width
							height
							url
						}
					}
				}
				href
				publishedAt
			}
		}
	}
	banners (
		sort: "publishedAt:desc",
		pagination: {
			limit: 1
		}
	) {
		data {
			id 
			attributes {
				image {
					data {
						id
						attributes {
							name
							width
							height
							url
						}
					}
				}
				internalName
				href
			}
		}
	}
	news (
		sort: "publishedAt:desc",
		pagination: {
			limit: 7
		}
	) {
		data {
			id
			attributes {
				title
				dropdownText
				bigImage {
					data {
						id
						attributes {
							name
							width
							height
							url
						}
					}
				}
				smallImage {
					data {
						id
						attributes {
							name
							width
							height
							url
							previewUrl
						}
					}
				}
				category {
					data {
						id
						attributes {
							name
						}
					}
				}
				slug
				publishedAt
			}
		}
	}
}
`