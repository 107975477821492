import { useLazyQuery } from "@apollo/client";
import { GetPaginaEstatica } from "../Library";
import { useEffect, useState } from "react";
import { IImage } from "./type";

interface IPaginaEstatica {
    id: string
    attributes: {
        headerImage: IImage
        content: string
        publishedAt: Date
        title: string
    }
}

export default function useStatic(slug: string) {
    const [fetch, { data, loading: externalLoading }] = useLazyQuery(GetPaginaEstatica(), {
        variables: {
            slug
        }
    })
    const [loading, setLoading] = useState<boolean>(true)
    const [staticFile, setStaticFile] = useState<IPaginaEstatica>()

    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (data?.paginasEstaticas?.data?.length === 1) {
            setStaticFile(data?.paginasEstaticas?.data[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        setLoading(externalLoading)
    }, [externalLoading])

    return {
        loading,
        data,
        staticFile
    }
}