import { DocumentNode, gql } from "@apollo/client";

export default function getPaginaEstatica(): DocumentNode {
    return gql`
query getPaginaEstatica($slug: String!) {
	paginasEstaticas (
		filters: {
			slug: {
				eq: $slug
			}
		}
	) {
		data {
			id
			attributes {
				title
				
				headerImage {
					data {
						id
						attributes {
							name
							width
							height
							url
						}
					}
				}
				slug
				content
				publishedAt
			}
		}
	}
}`
}