import footerSVG from '../../Assets/FooterSVG.svg';
import Icon from '../../Assets/Icon';
import LogoFooter from '../../Assets/LogoFooter';
import useMobile from '../../Hooks/useMobile';

export default function Footer() {
    const { isMobile } = useMobile()

    return <footer className="App_Footer" style={{
        backgroundImage: `url(${footerSVG})`,
    }}>

        <div className="App_Footer_ContactBox">
            <h4>Ubicación y Teléfonos</h4>

            <p>
                <i>Educando por un planeta verde</i><br />
                Nuestro colegio está ubicado en Calle Nueva 2801, Coquimbo.
            </p>
            <p>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/eusebiolillo.colegio/">
                    <Icon size={isMobile ? 96 : 64} type='instagram' />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Colegioeusebiolillo?locale=es_LA">
                    <Icon size={isMobile ? 96 : 64} type='facebook' />
                </a>
                <a target="_blank" rel="noreferrer" href="https://wa.me/56922017151">
                    <Icon size={isMobile ? 96 : 64} type='whatsapp' />
                </a>
            </p>
        </div>

        <LogoFooter />
    </footer>
}