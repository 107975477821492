// export function attemptLogin({ email, password, remember }: any) {
//     return async (dispatch: any) => {
//         return axios
//             .post(`https://app.bodegaenlinea.cl/api/auth/login`, {
//                 email,
//                 password,
//                 remember
//             })
//             .then((response: any) => {
//                 const firstName = response.data.user.name.split(' ')[0]
//                 const permissions = response.data.user.permissionInfo.reduce((acc: string[], perm: any) => {
//                     acc.push(perm.permission)
//                     return acc
//                 }, [])

//                 dispatch({
//                     type: LOGIN_USER,
//                     payload: {
//                         token: response.data.token,
//                         userInfo: {
//                             ...response.data.user,
//                             firstName
//                         },
//                         permissions,
//                         isLogged: true
//                     }
//                 })
//                 return response.data
//             })
//             .catch(err => {
//                 dispatch({
//                     type: LOGIN_USER,
//                     payload: {
//                         token: null,
//                         userInfo: null,
//                         isLogged: false
//                     }
//                 })
//                 return err.response.data
//             })
//     }
// }

export function fetchUpdate(payload: any) {
    return { type: 'UPDATE', payload }
}

export function logout(payload: any) {
    return { type: 'LOGOUT', payload }
}