import { DocumentNode, gql } from "@apollo/client";

export default function getArticle(id: number): DocumentNode {
    const replaceId = isNaN(id) ? 's' : `(
        id: ${id}
    )`
    return gql`
query getArticle {
    new${replaceId} {
		data {
            id
			attributes {
                title
                dropdownText
				bigImage {
					data {
                        id
						attributes {
                            name
                            width
                            height
                            url
                        }
                    }
                }
				smallImage {
					data {
                        id
						attributes {
                            name
                            width
                            height
                            url
                            previewUrl
                        }
                    }
                }
				category {
					data {
                        id
						attributes {
                            name
                        }
                    }
                }
                slug
                content
                publishedAt
            }
        }
    }
}`
}