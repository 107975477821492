import { createStore, applyMiddleware } from 'redux'

// Persistencia - Rehydratation
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Métodos asincrónicos
import thunk from 'redux-thunk'

import rootReducer from './Reducers/'

const persistConfig = {
    key: 'CELv20240229',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function Store() {
    const store = createStore(persistedReducer, applyMiddleware(thunk))
    const persistor = persistStore(store)
    return { store, persistor }
}