import useMobile from '../../Hooks/useMobile';
import { Footer, Header, Spinner } from '../../Components';
import useInfo from '../../Hooks/useInfo';
import { IGallery } from '../../Hooks/type';

import './GalleryView.scss'

const GalleryWrapper = ({ galerias, perRow }: { galerias: IGallery[], perRow: number }) => {
    const { isMobile } = useMobile()

    return <div className="GalleryWrapper">
        {galerias.map(gallery => (
            <div className="GalleryWrapper__Item">
                <a href={gallery.attributes.href} target='_new' className="GalleryWrapper__Item__Link">
                    <img className="GalleryWrapper__Item__Image" src={gallery.attributes.portrait.data?.attributes.url} alt={gallery.attributes.title} />
                </a>
                {isMobile && <div className="GalleryWrapper__Item__Information">
                    <h4>{gallery.attributes.title}</h4>
                    <p>{gallery.attributes.description}</p>
                </div>}
            </div>
        ))}
    </div>
}

export default function GalleryView () {
    const { ViewportClass } = useMobile()
    const { banners, galerias, loading } = useInfo() // , typeEvents, menues

    if (loading) {
        return <Spinner />
    }

    return <div className={`App ${ViewportClass}`}>
        <Header />


        {/* Banner y Galerías de Fotografías */}
        <h1 className='App_Row'>Galería de Imágenes</h1>
        <div className="App_Row">
            <GalleryWrapper galerias={galerias} perRow={banners.length ? 4 : 2} />
        </div>

        <Footer />
    </div>
}